import React from 'react';
import { Grid, Row } from 'react-flexbox-grid';
import { navigate } from 'gatsby-link';
import { useSelector } from 'react-redux';
import SectionStyled from '../shared/SectionStyled';
import AutoCol from '../shared/AutoCol';
import MainText from '../shared/MainText';
import PageNotFoundLocales from './PageNotFoundLocales.json';
import ButtonStyled from '../shared/ButtonStyled';

const PageNotFound = () => {
  const lang = useSelector(state => state.lang);
  const locales = PageNotFoundLocales[lang];

  const onButtonClick = () => {
    navigate('/');
  };


  return (
    <SectionStyled
      id="404"
      background="black"
    >
      <Grid>
        <Row center="xs" start="md">
          <AutoCol>
            <MainText color="white">
              404
            </MainText>
            <MainText color="white">
              {locales.pageNotFound}
            </MainText>
            <ButtonStyled color="white" onClick={onButtonClick}>
              {locales.comeBack}
            </ButtonStyled>
          </AutoCol>
        </Row>
      </Grid>
    </SectionStyled>
  );
};

export default PageNotFound;
